
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  justify-content: center;
  scroll-snap-align: start;
  gap: 1rem;
  white-space: nowrap;
  flex: 1;

  @include for-desktop-up {
    width: 48vw;
  }

  @include for-desktop-up {
    width: fit-content;
  }
}
.label {
  width: 78%;

  text-align: center;
  @include for-desktop-up {
    width: 70%;
  }
}

.badge {
  color: var(--color-secondary);
  border-radius: 50%;
  //   width: 50px;
  //   height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include for-desktop-up {
    width: 60px;
    height: 60px;
  }
}
